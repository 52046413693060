export const getInitials = (str: string): string => {
  if (!str) return '';

  const initials = [];
  for (const word of str.split(' ')) {
    if (word.length === 0) continue;
    initials.push(word[0].toUpperCase());
  }
  return initials.slice(0, 2).join('');
};
