import { getCookie } from './cookie';
import i18n from 'localizations';
import { defaultCountryCode } from '../localizations';

const getLocaleCurrencyLabel = (): string => {
  try {
    const { countryCode } = JSON.parse(getCookie('location') || `{ "countryCode": "${defaultCountryCode}" }`) as {
      countryCode: string;
    };

    switch (countryCode) {
      case 'US': {
        return i18n.t('currency.usd');
      }

      case 'UA': {
        return i18n.t('currency.uah');
      }

      default:
        return i18n.t('currency.uah');
    }
  } catch (e) {
    console.error(e);
    return i18n.t('currency.uah');
  }
};

export default getLocaleCurrencyLabel;
